import $api from '@/helpers/config-axios'

import authHeader from './auth-header'
import store from '@/store'
import {errorHandler} from '@/assets/scripts/scripts'
import {eventBus} from "@/main";

class OrdersService {
  getAllOrders() {
    store.commit('other/onChangeLoadingItems', true)
    const {
      paginationPage,
      orderBy,
      sortedBy,
      filter,
      search,
      selectedItemsForDelete,
      isArchive,
      section
    } = store.state.orders

    let newParams = {
      page: paginationPage,
      include: 'object,pipeline,status,created_by,updated_by,responsible_by',
      searchJoin: 'and',
    }

    if (orderBy !== null) newParams['orderBy'] = orderBy
    if (sortedBy !== null) newParams['sortedBy'] = sortedBy
    if (filter.main) newParams['search'] = filter.main
    if (search !== '') newParams['find'] = search
    if (section.id) {
      section.type === 'funnel'
        ? newParams['search'] = `pipeline_id: ${section.id}`
        : newParams['search'] = `status_id: ${section.id}`
    }

    if (isArchive) newParams['only-trashed'] = `1`

    return $api
      .get('orders', {
        params: newParams,
        headers: authHeader(),
      })
      .then (async r => {
        await store.commit('orders/getAllOrders', r.data)
        await eventBus.$emit('update-table-settings', r.data.meta.custom.settings.value.data)
        await store.commit('other/onChangeLoadingItems', false)
        errorHandler(r)
        return {...r, section: section.id}
      })
  }

  _refactorTree(arr) {
    return arr.map(el => {
      return {
        ...el,
        edit: false,
        new: false,
        data: [],
        children: el['statuses']['data'].map(child => {
            return {
              ...child,
              edit: false,
              new: false,
              data: [],
            }
          }
        ),
      }
    })
  }

  async getTree() {
    store.commit('other/onChangeLoadingSidebar', true)
    return await $api
      .get('order/pipelines', {
        params: {
          include: 'statuses',
        },
        headers: authHeader(),
      })
      .then(r => {
        store.commit('orders/updateTree', this._refactorTree(r.data.data))
        eventBus.$emit('update-elements-tree')
        store.commit('other/onChangeLoadingSidebar', false)
      })
  }

  async getOrder(id) {
    return await $api
      .get(`orders/${id}`, {
        params: {
          include: 'object,pipeline,status,created_by,updated_by,responsible_by,participants,last_events,last_notes',
        },
        headers: authHeader(),
      })
  }

  async createOrder(data) {
    return await $api
      .post('orders', data, {
        headers: authHeader(),
      })
  }

  async updateOrder(id, data) {
    return await $api
      .patch(`orders/${id}`, data, {
        headers: authHeader(),
      })
  }

  deleteItems(permanent = false) {
    let newParams = {}
    if (permanent) newParams['force-delete'] = 1

    return $api.delete('orders', {
      params: newParams,
      headers: authHeader(),
      data: {
        ids: store.state.orders.selectedItemsForDelete,
      },
    })
  }

  archiveItems() {
    return $api.post(
      'restore/orders',
      {
        ids: store.state.orders.selectedItemsForDelete,
      },
      {
        headers: authHeader(),
      }
    )
  }

  async getFunnels(list = false) {
    const params = {}
    if (list) params['to'] = 'list'

    return await $api
      .get('order/pipelines', {
        params,
        headers: authHeader(),
      })
  }

  async createFunnel(data) {
    return await $api
      .post('order/pipelines', data, {
        headers: authHeader(),
      })
  }

  async updateFunnel(id, data) {
    return await $api
      .patch(`order/pipelines/${id}`, data, {
        headers: authHeader(),
      })
  }

  async deleteFunnel(id) {
    return await $api
      .delete(`order/pipelines`, {
        headers: authHeader(),
        data: {
          ids: [id],
        },
      })
  }

  async getOrderStatuses(list = false) {
    const params = {
      include: 'pipeline',
      limit: '*'
    }
    if (list) params['to'] = 'list'

    return await $api
      .get('order/statuses', {
        params,
        headers: authHeader(),
      })
  }

  async getStage(id) {
    return await $api
      .get(`order/statuses/${id}`, {
        params: {
          include: 'pipeline',
        },
        headers: authHeader(),
      })
  }

  async getPipeline(id) {
    return await $api.get(`order/pipelines/${id}`, {
      params: {
        include: 'params_schema',
      },
      headers: authHeader(),
    })
  }

  async createStage(data) {
    return await $api
      .post('order/statuses', data, {
        headers: authHeader(),
      })
  }

  async updateStage(id, data) {
    return await $api
      .patch(`order/statuses/${id}`, data, {
        headers: authHeader(),
      })
  }

  async deleteStage(id) {
    return await $api
      .delete(`order/statuses`, {
        headers: authHeader(),
        data: {
          ids: [id],
        },
      })
  }
}

export default new OrdersService()
